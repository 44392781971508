// import React, { useEffect, Component } from 'react';
import React from 'react';
import { ChakraProvider, Box, Image, Flex, Text, Stack, Button } from '@chakra-ui/react';
import ProductCard from './ProductCard';
import { Helmet } from "react-helmet";
import backgroundImage from "./background.jpg";
import Logo from "./Logo.png";
import Nitro from "./Nitro.png";
import Boosts from "./Boosts.png";
import Disney from "./disney.png";
import Spotify from "./spotify.png";
import Banner from "./banner.png";
// import { Crisp } from "crisp-sdk-web";
// import ScriptTag from 'react-script-tag';

// import { ColorModeSwitcher } from './ColorModeSwitcher';
// import { Logo } from './Logo';

// class App extends Component {
  // componentDidMount() {
  //   Crisp.configure("4c68df26-b844-45f6-8de9-b4c1e7b9de8e");
  // }

function App(){
  function scrollTo(element){
    const section = document.querySelector(element);

    if(section){
      section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    }
  };

  const productData = [
  {
    SEOTitle: "Discord-Nitro-Monthly",
    title: "Discord Nitro Boost",
    imageURL: Nitro,
    price: 2.5,
    ticks: ["Gift Link", "Full Warranty", "Instant Delivery", "2 Boosts"],
    sellixID: "66698a9869a97",
  },
  {
    SEOTitle: "Discord-Nitro-Basic",
    title: "Discord Nitro Basic",
    imageURL: Nitro,
    price: 0.7,
    ticks: ["Gift Link", "Full Warranty", "Instant Delivery", "Legally Paid"],
    sellixID: "666995e18e631",
  },
  {
    SEOTitle: "1",
    title: "14 Server Boosts",
    imageURL: Boosts,
    price: 2,
    ticks: ["Full Support", "Full Warranty", "1 or 3 Months", "Level 3 Server"],
  },
  {
    SEOTitle: "2",
    title: "Disney+",
    imageURL: Disney,
    price: 1,
    ticks: ["1 Week Option", "1 Month Option", "1 Year Option", "Replacement Warranty"],
  },
  {
    SEOTitle: "3",
    title: "Spotify Premium",
    imageURL: Spotify,
    price: 1,
    ticks: ["Ad-Free Listen", "Download Songs", "High Quality Audio", "And more!"],
  },
];

  // useEffect(() => {
  //   const bg = document.getElementById("bg-img");

  //   // bg.style.setProperty("background-color", "#FAE6B3")
  // }, []);

  const discordInviteLink = "https://discord.gg/vntvYWEXnZ";

  return(
    <ChakraProvider>
      <div>
        <Helmet>
          <title>Cheap Discord Nitro Gifts - AllStars</title>
          <meta charset="UTF-8"/>
          <link rel="canonical" href="https://nitro.codes"/>
          <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
          <meta name="description" content="Get the best deals on Discord Nitro gifts! Join our community for exclusive offers and discounts."/>

          <meta property="og:title" content="Cheap Discord Nitro Gifts - AllStars"/>
          <meta name="twitter:title" content="Cheap Discord Nitro Gifts - AllStars"/>
          <meta property="og:type" content="website"/>
          <meta property="og:url" content="https://nitro.codes"/>
          <meta property="og:image" content="https://i.postimg.cc/6qtBppWj/Nitro.png"/>
          <meta property="og:description" content="Get the best deals on Discord Nitro gifts! Join our community for exclusive offers and discounts."/>
          <meta name="twitter:description" content="Get the best deals on Discord Nitro gifts! Join our community for exclusive offers and discounts."/>
          <meta name="twitter:image" content="https://i.postimg.cc/6qtBppWj/Nitro.png"/>
          <meta name="twitter:site" content="@discord"/>
          <meta property="og:site_name" content="AllStars"/>
          <meta name="robots" content="index, follow"/>

          <meta name="twitter:card" content="summary_large_image"/>

          <meta name="color-scheme" content="dark"/>
          <meta name="darkreader-lock"/>

          <script src="https://cdn.sellix.io/static/js/embed.js"></script>
          <link href="https://cdn.sellix.io/static/css/embed.css" rel="stylesheet"/>
        </Helmet>

        <Box style={{ overflowX: 'hidden' }} height="100vh" width="100vw" backgroundColor={"#000"} overflowX={"none"}>
          <Box height={"auto"} position={"relative"} className='home'>

            <Box width="100vw" height="100vh" overflow="hidden" position={"absolute"} marginTop={-10} id="bg-img">
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
                }}
              >
              <img
                  src={backgroundImage}
                  alt="loading"
                  style={{
                    minWidth: "100%",
                    minHeight: "100%",
                    objectFit: "cover",
                    opacity:"0.2",
                    position: "absolute",
                    top: -30,
                    left: 0,
                  }}
                />
              </div>
            </Box>

            {/* Header Navigation */}
            <Flex
              className='header'
              width="80%"
              marginTop={10}
              position={'relative'}
              left="50%"
              transform={"translateX(-50%)"}
              // display={"none"}
            >
              <Stack direction={"row"} spacing={10} alignItems={'center'} color="#907F54">
                <Image alt="logo" width={"8%"} minWidth={"90px"} src={Logo} _hover={{ cursor: "pointer", transform: "scale(1.05)" }} transition={"0.1s"}></Image>
                <Text id="top-nav" display={"block"} _hover={{ color: "#000", cursor: "pointer" }} transition={"0.1s"} color="#4E4532">Home</Text>
                <Text id="top-nav" display={"block"} onClick={() => scrollTo("#productPage")} _hover={{ color: "#443c28", cursor: "pointer" }} transition={"0.1s"}>Products</Text>
                <Text id="top-nav" display={"block"} onClick={() => scrollTo("#contactPage")} _hover={{ color: "#443c28", cursor: "pointer" }} transition={"0.1s"}>Status</Text>
                <Text id="top-nav" display={"block"} onClick={() => scrollTo("#contactPage")} _hover={{ color: "#443c28", cursor: "pointer" }} transition={"0.1s"}>Contact</Text>
                <Text id="top-nav" display={"block"} onClick={() => scrollTo("#aboutusPage")} _hover={{ color: "#443c28", cursor: "pointer" }} transition={"0.1s"}>About Us</Text>
              </Stack>

              <Stack
                marginLeft={"auto"}
                direction={"row"}
                spacing={5}
                alignItems={'center'}
                color="white"
              >
                  <a href={discordInviteLink} target="_blank" rel="noopener noreferrer">
                    <Image alt="loading" width="40px" marginRight={5} filter={"brightness(0%)"} src="https://assets-global.website-files.com/6257adef93867e50d84d30e2/636e0a6a49cf127bf92de1e2_icon_clyde_blurple_RGB.png"></Image>
                  </a>
                  <Button
                    onClick={() => scrollTo("#productPage")}
                    backgroundColor={"#FBEFD0"}
                    color={"#1B0F49"}
                    _hover={{ backgroundColor: "#f9e9bd" }}
                    _active={{ transform: "translate(4px, 4px)", boxShadow: "0px 0px #242403" }}
                    boxShadow="4px 4px #242403"
                    transition={"0.1s"}
                    paddingLeft={"30px"}
                    paddingRight={"30px"}
                    height="40px"
                  >
                    Buy Now
                  </Button>
              </Stack>
            </Flex>

            {/* Main Content */}
            <Box
              display={"flex"}
              width={"80%"}
              marginTop={"10%"}
              position={'absolute'}
              left="50%"
              transform={"translateX(-50%)"}
              id="main-container"
            >
              <Box id="title-container" marginRight={20} flex={1} maxWidth={"60%"}>
                <Text id="redhatdisplay" color="#444434">Easy Access</Text>
                <Text marginTop={2} width={"90%"} id="title" color="#242403" fontSize={{ base: "3em", md: "5em" }}>The Cheapest Store You Need</Text>
                <Text id="paragraph" fontSize={{ base: "0.75em", md: "1em" }}>Looking for the best prices? Check out ALLSTARS, the cheapest store you need! We offer unbeatable deals! Dive into our vast selection and discover prices that make sense for your wallet. Shop smart, shop Allstars, and see just how affordable products can be.</Text>
                <Button
                  onClick={() => scrollTo("#productPage")}
                  marginTop={30}
                  marginRight={5}
                  fontSize={18}
                  borderRadius={3}
                  textTransform={'uppercase'}
                  backgroundColor={"#FBD89A"}
                  paddingLeft={5}
                  paddingRight={5}
                  paddingTop={6}
                  paddingBottom={6}
                  color={"#1B0F49"}
                  _hover={{ backgroundColor: "#fad28b" }}
                  _active={{ backgroundColor: "#818def" }}
                  boxShadow="0px 0px 38px 2px rgba(0, 0, 0, 0.25)"
                  transition={"0.25s"}
                >
                  Products
                </Button>
                <Button
                  onClick={() => scrollTo("#aboutusPage")}
                  marginTop={30}
                  fontSize={18}
                  borderRadius={3}
                  textTransform={'uppercase'}
                  outline={'2px solid #242403'}
                  outlineOffset={-2}
                  background={"none"}
                  color={"#242403"}
                  paddingLeft={5}
                  paddingRight={5}
                  paddingTop={6}
                  paddingBottom={6}
                  _hover={{ backgroundColor: "rgba(255, 255, 255, 0.2)" }}
                  _active={{ backgroundColor: "#818def" }}
                  transition={"0.25s"}
                >
                  About Us
                </Button>
              </Box>

              {/* Additional content or image
              <Image
                position={"relative"}
                right={0}
                top={20}
                opacity={0.75}
                src="https://i.imgur.com/lBSZlGZ.png"
              /> */}
            </Box>
            
            {/* Products Section */}
            <Box
              id="productPage"
              width={"80%"}
              position={'relative'}
              left="50%"
              transform={"translateX(-50%)"}
              marginTop={"100vh"}
            >
              <Box id="title-container-sections" maxWidth={"60%"} marginBottom={10}>
                <Text marginTop={2} id="phantom" color="#fff" fontSize={"4em"}>Products</Text>
              </Box>

              <Flex flexDirection={'row'} flexWrap={'wrap'} justifyContent={'center'} alignItems={'center'} marginBottom={15} gap={5}>
                {productData.map((product, index) => (
                  <div key={index}>
                    <ProductCard SEOTitle={product.SEOTitle} title={product.title} imageURL={product.imageURL} price={product.price} ticks={product.ticks} sellixID={product.sellixID} />
                  </div>
                ))}
              </Flex>
            </Box>

            {/* Other content such as Reviews, About Us, Contact Us, etc. */}
            {/* <Box
              id="reviews"
              marginTop={"60px"}
              width={"80%"}
              position={'relative'}
              left="50%"
              transform={"translateX(-50%)"}
            >
              <Box maxWidth={"100%"} id="title-container-sections" marginBottom={10}>
                <Text marginTop={2} id="phantom" color="#fff" fontSize={"4em"}>Top Reviews</Text>
              </Box>

              <Flex flexWrap='wrap' justifyContent='center' alignItems='center' gap={10}>
                <Box borderRadius={10} backgroundColor={"#141414"} height='auto' width='450px' >
                  <Stack direction={"row"} spacing="10px" margin={5} marginBottom={2} gap={2} alignItems={'center'}>
                    <Box borderRadius={10} overflow={"hidden"} width="60px" height="60px" backgroundColor="yellow.200">
                      <Image alt="loading" src="https://i.pinimg.com/1200x/fa/e6/50/fae650000003558d4924798f10ca9d76.jpg" width="100%"></Image>
                    </Box>
                    <Text id="cardTitle" color={"#fff"} letterSpacing={2}>kofi99</Text>
                  </Stack>
                  <Text marginLeft={5} marginRight={5} color={"#A4A5B1"} paddingBottom={5}>Yo, AllStars rocks! Discord Nitro, Spotify, and Disney+ all in one place? It's like Christmas every day!</Text>
                </Box>

                <Box borderRadius={10} backgroundColor={"#141414"} height='auto' width='450px' >
                  <Stack direction={"row"} spacing="10px" margin={5} marginBottom={2} gap={2} alignItems={'center'}>
                    <Box borderRadius={10} overflow={"hidden"} width="60px" height="60px" backgroundColor="yellow.200">
                      <Image alt="loading" src="https://i.pinimg.com/736x/49/a6/57/49a65734709b3fd804a3bca26a2bb005.jpg" width="100%"></Image>
                    </Box>
                    <Text id="cardTitle" color={"#fff"} letterSpacing={2}>atere</Text>
                  </Stack>
                  <Text marginLeft={5} marginRight={5} color={"#A4A5B1"} paddingBottom={5}>AllStars is lit! Got my Discord Nitro, Spotify tunes, and Disney+ shows sorted</Text>
                </Box>

                <Box borderRadius={10} backgroundColor={"#141414"} height='auto' width='450px' >
                  <Stack direction={"row"} spacing="10px" margin={5} marginBottom={2} gap={2} alignItems={'center'}>
                    <Box borderRadius={10} overflow={"hidden"} width="60px" height="60px" backgroundColor="yellow.200">
                      <Image alt="loading" src="https://i.pinimg.com/736x/d8/b5/9d/d8b59d872071c6ca11c4f6bc515e52ed.jpg" width="100%"></Image>
                    </Box>
                    <Text id="cardTitle" color={"#fff"} letterSpacing={2}>phninx</Text>
                  </Stack>
                  <Text marginLeft={5} marginRight={5} color={"#A4A5B1"} paddingBottom={5}>Thought this was a scam at first but I tried it and its legitt. I buy the nitro like every day</Text>
                </Box>

                <Box borderRadius={10} backgroundColor={"#141414"} height='auto' width='450px' >
                  <Stack direction={"row"} spacing="10px" margin={5} marginBottom={2} gap={2} alignItems={'center'}>
                    <Box borderRadius={10} overflow={"hidden"} width="60px" height="60px" backgroundColor="yellow.200">
                      <Image alt="loading" src="https://wallpapers-clan.com/wp-content/uploads/2022/10/rapper-pfp-6.jpg" width="100%"></Image>
                    </Box>
                    <Text id="cardTitle" color={"#fff"} letterSpacing={2}>marveldc3</Text>
                  </Stack>
                  <Text marginLeft={5} marginRight={5} color={"#A4A5B1"} paddingBottom={5}>The nitro is legit. I haven't tried anything else but this nitro fire 🔥🔥</Text>
                </Box>

                <Box borderRadius={10} backgroundColor={"#141414"} height='auto' width='450px' >
                  <Stack direction={"row"} spacing="10px" margin={5} marginBottom={2} gap={2} alignItems={'center'}>
                    <Box borderRadius={10} overflow={"hidden"} width="60px" height="60px" backgroundColor="yellow.200">
                      <Image alt="loading" src="https://i.pinimg.com/1200x/fa/e6/50/fae650000003558d4924798f10ca9d76.jpg" width="100%"></Image>
                    </Box>
                    <Text id="cardTitle" color={"#fff"} letterSpacing={2} as="i">anonymous</Text>
                  </Stack>
                  <Text marginLeft={5} marginRight={5} color={"#A4A5B1"} paddingBottom={5}>How tf is this so cheap and how have i only heard about it now. i buy from here and resell to my friends, profitt</Text>
                </Box>
              </Flex>
            </Box> */}


            <Box
              id="aboutusPage"
              marginTop={"60px"}
              width={"80%"}
              position={'relative'}
              left="50%"
              transform={"translateX(-50%)"}
              marginBottom={20}
            >
              <Box maxWidth={"100%"} id="title-container-sections" marginBottom={10}>
                <Text marginTop={2} id="phantom" color="#fff" fontSize={"4em"}>About Us</Text>
              </Box>

              <Text color={"#C7C9DD"} fontSize={{ base: "0.9em", md: "1.25em" }}>Welcome to AllStars – your one-stop destination for premium services at unbeatable prices! At AllStars, we're dedicated to providing you with top-notch products like Discord Nitro, Spotify Premium, and Disney+ subscriptions, all at jaw-droppingly low rates. But here's the best part: everything we offer is 100% legal. How do we do it? Well, we've mastered the art of leveraging various methods such as region changes, specialized tools, and other ingenious strategies to bring you these services without breaking a sweat. Our mission is simple: to make the best services accessible to everyone, regardless of budget constraints. With AllStars, you can enjoy the perks of Discord Nitro, Spotify Premium, and Disney+ without burning a hole in your pocket. So why wait?</Text>
            </Box>

            
            <Box
              width="100vw"
              // height="600px"
              backgroundImage={Banner}
              backgroundSize="cover"
              position="relative"
              id="contactPage"
              // padding="150px"
              paddingLeft={150}
              paddingRight={150}
              paddingTop={150}
              paddingBottom={150}
              zIndex={0}
            >
              <Box
                width="100%"
                height="100%"
                justifyContent={'center'}
                alignItems={'center'}
                backgroundColor="rgba(255, 255, 255, 0.02)"
                backdropFilter="blur(35px)"
                zIndex={1}
                position="absolute"
                top="0"
                left="0"
                right="0"
                bottom="0"
              />
              
              <Stack justifyContent={'center'} alignItems={'center'} direction={"column"} spacing={5} position={'relative'} zIndex={1}>
                <Text color={"#fff"} fontSize={"3em"} fontWeight={600}>Talk to us now!</Text>
                <Text color={"#0C113D"}fontSize={{ base: "0.9em", md: "1em" }} textAlign={"center"}>Join us in our Discord community, the heart of AllStars, your go-to hub for all things gaming, entertainment, and more. Engage with fellow gamers, share your experiences, and unlock exclusive access to the best deals and offers on Discord Nitro, Disney+, Spotify, and Discord boosts. Connect with like-minded individuals, discover new gaming strategies, and dive into exciting discussions about the latest releases and updates. Whether you're a seasoned gamer or just starting your journey, our Discord server is your ticket to a vibrant community and endless gaming possibilities.<br />Join us today and become part of the AllStars family!</Text>
                
                <a href={discordInviteLink} target="_blank" rel="noopener noreferrer">
                  <Button _hover={{ backgroundColor: "#5e82ff" }} borderRadius={20} backgroundColor={"#3663FF"} width={"200px"} height={"75px"}>
                    <Stack direction={"row"} justifyContent={"center"} alignItems={"center"} height="100%" gap={2}>
                      <Image alt="loading" width="50px" filter={"brightness(500%)"} src="https://assets-global.website-files.com/6257adef93867e50d84d30e2/636e0a6a49cf127bf92de1e2_icon_clyde_blurple_RGB.png"></Image>
                      <Text color={"#fff"} fontSize={24}>Discord</Text>
                    </Stack>
                  </Button>
                </a>
              </Stack>
            </Box>
          </Box>
        </Box>
      </div>
    </ChakraProvider>
  );
}

export default App;

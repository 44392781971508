import { Box, Image, Text, SimpleGrid, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, ModalFooter, useDisclosure } from "@chakra-ui/react";
import React from "react";

function ProductCard({ title, imageURL, price, SEOTitle, ticks, sellixID }){
    // function clicked(){
    //     // const iframe = document.createElement("iframe");
        
    //     // iframe.src = `https://sellpass-test.itsme12453.repl.co/embed/${SEOTitle}`;
    //     // document.body.appendChild(iframe);

    //     document.getElementById(SEOTitle+"Box").style.zIndex = 99;
    //     document.getElementById(SEOTitle+"Box").style.display = "block";
    // }

    const { isOpen, onOpen, onClose } = useDisclosure()

    return (
        <Box borderRadius={6} id="product" width={300} height={"auto"} backgroundColor={"rgba(16, 16, 16, 0.50)"}>

            <Box zIndex={0}>
                <Image borderTopRadius={6} width="100%" src={imageURL}></Image>

                <Box paddingLeft={3} paddingRight={3} marginTop={2} paddingBottom={3}>
                    <Text textTransform={"uppercase"} noOfLines={1} id="cardTitle" color="#fff" fontSize={"xl"}>{title}</Text>
                    {/* <Text marginTop={-1} color={"#dfdfdf"}>{title}</Text> */}
                    <p id="cardP">We guarantee instant delivery, warranties and much more.</p>

                    <Box marginTop={3} marginBottom={3} width={"100%"} height={0.1} backgroundColor={"rgba(79, 79, 122, 0.7)"}></Box>

                    {/* <CheckIcon boxSize={5} color={"#7F7FBA"}/> */}

                    <SimpleGrid spacing={1.5} marginBottom={6}>
                        <Box display={"flex"}>
                            <Image marginRight={1} transform={"scale(0.7)"} src="https://i.imgur.com/99XtKtK.png"></Image>
                            <p id="cardTick">{ticks[0]}</p>
                        </Box>
                        <Box display={"flex"}>
                            <Image marginRight={1} transform={"scale(0.7)"} src="https://i.imgur.com/99XtKtK.png"></Image>
                            <p id="cardTick">{ticks[1]}</p>
                        </Box>
                        <Box display={"flex"}>
                            <Image marginRight={1} transform={"scale(0.7)"} src="https://i.imgur.com/99XtKtK.png"></Image>
                            <p id="cardTick">{ticks[2]}</p>
                        </Box>
                        <Box display={"flex"}>
                            <Image marginRight={1} transform={"scale(0.7)"} src="https://i.imgur.com/99XtKtK.png"></Image>
                            <p id="cardTick">{ticks[3]}</p>
                        </Box>
                    </SimpleGrid>

                    <Box display={"flex"} data-sellix-group={sellixID} style={{ cursor: "pointer" }}>
                        {/* <Button onClick={clicked} backgroundColor={"#848FEF"} borderRadius={3} color={"#1B0F49"} _hover={{ backgroundColor: "#616fea" }} _active={{ backgroundColor: "#818def" }} transition={"0.25s"}>Buy Now</Button> */}
                        <Button
                            type="submit"
                            alt="Buy Now with sellix.io"
                            onClick={!sellixID ? onOpen : console.log()}
                            style={{ cursor: "pointer" }}
                        backgroundColor={"#848FEF"} borderRadius={3} color={"#1B0F49"} _hover={{ backgroundColor: "#616fea" }} _active={{ backgroundColor: "#818def" }} transition={"0.25s"}>Buy Now</Button>
                        <Text marginLeft={"auto"} id="cardTitle" lineHeight={"normal"} color="#fff" fontSize={30} ><span style={{ color: "#A4A5B1", fontSize: 15 }}>STARTING AT</span> ${price}</Text>
                    </Box>
                </Box>
            </Box>

            <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Coming Soon!</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                OUT OF STOCK! Join our <Text display={"inline"} _hover={{ textDecoration: "underline" }} color="#3663FF"><a href="https://discord.gg/vntvYWEXnZ">Discord</a></Text> to know as soon as we next get stock!
                <br/>
              </ModalBody>

              <ModalFooter>
                <Button colorScheme='blue' mr={3} onClick={onClose}>
                  Close
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </Box>
    )
}

export default ProductCard